import React, { ReactElement } from 'react';

interface UserMarkerSvgProps {
  classNames?: string;
}

export default function UserMarkerSvg({ classNames }: UserMarkerSvgProps): ReactElement {
  return (
    <svg
      width="46"
      height="64"
      viewBox="0 0 46 64"
      fill="none"
      style={{
        position: 'absolute',
        transform: 'translate(-50%, -90%) scale(.75, .75)',
      }}
      className={`cursor-default ${classNames} fill-current`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.083 0.536621C10.4132 0.536621 0.166016 10.4686 0.166016 22.7488C0.166016 39.4079 23.083 64 23.083 64C23.083 64 46 39.4079 46 22.7488C46 10.4686 35.7528 0.536621 23.083 0.536621ZM23.083 30.6817C18.5651 30.6817 14.8984 27.1278 14.8984 22.7488C14.8984 18.3698 18.5651 14.8159 23.083 14.8159C27.6009 14.8159 31.2676 18.3698 31.2676 22.7488C31.2676 27.1278 27.6009 30.6817 23.083 30.6817Z"
        fill="current"
      />
    </svg>
  );
}
