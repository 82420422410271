import SharedLocationDataContext from 'context/SharedLocationDataContext';
import React, { ReactElement, useContext } from 'react';
import { IPageLocationFields } from 'types/contentful';
import CTA from './Cta';

interface ScheduleAppointmentCtaProps {
  location: IPageLocationFields;
  classNames?: string;
  colorScheme?: 'Primary' | 'Primary2' | 'Primary3' | 'Outlined' | 'White';
  wrapperClasses?: string;
  linkStyle?: boolean;
  ctaTextSuffix?: string;
  text?: string;
}

const DEFAULT_TEXT = 'Schedule An Appointment';
const CALL_TO_SCHEDULE_TEXT = 'Call To Schedule An Appointment';
const DEFAULT_LINK_STYLES = 'text-tertiary-actual underline';

export default function ScheduleAppointmentCta({
  location,
  colorScheme,
  classNames = '',
  wrapperClasses,
  linkStyle,
  ctaTextSuffix = '',
  text,
}: ScheduleAppointmentCtaProps): ReactElement {
  const { scheduleAppointmentCtaText } = useContext(SharedLocationDataContext);
  const callToSchedule = location.disableScheduling === true || !location.schedulingUrl;
  const isPatientPortalScheduleUrl = location.schedulingUrl?.includes(
    'eyecare-partners.com/patient'
  );
  const slug = callToSchedule
    ? `tel:${location.phoneNumber}`
    : isPatientPortalScheduleUrl && location.schedulingUrl
    ? `${location.schedulingUrl}?location=${location.city}, ${location.state}`
    : location.schedulingUrl || '';
  const openInNewTab = slug.startsWith('http');
  const actionText = text
    ? callToSchedule
      ? `Call To ${text}`
      : text
    : callToSchedule
    ? CALL_TO_SCHEDULE_TEXT
    : scheduleAppointmentCtaText || DEFAULT_TEXT;

  const actionTextWithSuffix = `${actionText} ${ctaTextSuffix}`;

  const schedCTA = linkStyle ? (
    <a href={slug} target="_blank" className={classNames || DEFAULT_LINK_STYLES} rel="noreferrer">
      {actionTextWithSuffix}
    </a>
  ) : (
    <CTA
      slug={slug}
      colorScheme={colorScheme || 'Primary'}
      text={actionTextWithSuffix}
      openInNewTab={openInNewTab}
      classNames={classNames}
    ></CTA>
  );

  return wrapperClasses ? <div className={wrapperClasses}>{schedCTA}</div> : schedCTA;
}
