import { createContext } from 'react';

interface ISharedLocationDataContext {
  scheduleAppointmentCtaText: string;
}

const SharedLocationDataContext = createContext<ISharedLocationDataContext>({
  scheduleAppointmentCtaText: '',
});

export default SharedLocationDataContext;
