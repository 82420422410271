import { ReactElement } from 'react';
import DirectionsOutlinedIcon from '@material-ui/icons/DirectionsOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Location } from 'pages/locations/index';
import { formatPhoneNumber } from 'lib/util';
import { IFragmentLocationBanner, IPageLocationFields } from 'types/contentful';
import ScheduleAppointmentCta from 'components/UI/ScheduleAppointmentCta';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CTA from './Cta';

const DIRECTIONS_URL = 'https://www.google.com/maps/dir/?api=1&destination=';

type LocationCardProps = {
  location: Location;
  className?: string;
  locationBanners: IFragmentLocationBanner[];
};

const useStyles = makeStyles({
  tooltip: {
    fontSize: '14px',
  },
});

const buildDirectionsUrl = (loc: IPageLocationFields): string =>
  encodeURI(`${DIRECTIONS_URL}${loc.address1} ${loc.city},${loc.state} ${loc.zipCode}`);

export default function LocationCard({
  location,
  className = '',
  locationBanners,
}: LocationCardProps): ReactElement {
  const classes = useStyles();
  const bannerText =
    location?.sysId &&
    locationBanners
      .filter(({ fields }) => fields.locations?.includes(location?.sysId || ''))
      .map((banner) => banner.fields.bannerText)
      .join(' -- ');

  return (
    <div className={`flex flex-col p-5 ${className}`}>
      <div className="font-bold text-lg mb-5 flex items-center">
        {bannerText && (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={bannerText} placement="top-start">
            <WarningIcon className="text-red" style={{ fontSize: 20, marginRight: '6px' }} />
          </Tooltip>
        )}
        {location.name}
      </div>

      <address className="not-italic mb-5">
        {location.address1} {location.address2}
        <br />
        {location.city}, {location.state} {location.zipCode}
      </address>

      {location.currentDistance && (
        <div className="flex items-center mb-5">
          <LocationOnIcon className="text-tertiary-actual mr-2" />{' '}
          {location.currentDistance.toPrecision(3)} miles
          <a
            href={buildDirectionsUrl(location)}
            target="_blank"
            className="flex ml-2 items-center text-tertiary-actual underline"
            rel="noreferrer"
          >
            <DirectionsOutlinedIcon className="mr-2" />
            Directions
          </a>
        </div>
      )}

      <a className="underline mb-5" href={`tel: ${location.phoneNumber}`}>
        {formatPhoneNumber(location.phoneNumber)}
      </a>

      <ScheduleAppointmentCta location={location} colorScheme="Primary" classNames="mb-5" />

      <CTA
        slug={`locations/${location.slug}`}
        colorScheme="Outlined"
        text="View Location Details"
      ></CTA>
    </div>
  );
}
